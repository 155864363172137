import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["agentSelect", "unknownAgentName"];

  connect() {
    this.toggleUnknown();
  }

  toggleUnknown() {
    const options = this.agentSelectTarget.options;
    const agent = options[options.selectedIndex].text;
    const matches = agent == "unknown"; // || agent != "Leasing Agent ";
    console.log(agent, matches);
    this.toggleVisibility(matches, [this.unknownAgentNameTarget]);
  }

  toggleVisibility(show, targets) {
    targets.forEach((target) => {
      target.classList.toggle("hidden", !show);
      target.disabled = !show;
    });
  }
}
