// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import { Application } from "stimulus";
import { definitionsFromContext } from "stimulus/webpack-helpers";

const application = Application.start();
const context = require.context("../controllers", true, /\.js$/);
application.load(definitionsFromContext(context));

import Rails from "@rails/ujs";
// import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";

Rails.start();
// Turbolinks.start();
ActiveStorage.start();

import "jquery";

import "../stylesheets/application";
import "materialize-css/dist/js/materialize";

import "vanilla-nested";

global.$ = require("jquery");
require("jquery-ui");

document.addEventListener("DOMContentLoaded", function () {
  const optionsModal = {
    onOpenStart: (elem) => {
      setContestedModalValues(elem);
    },
  };

  var elems = document.querySelectorAll(".modal");
  M.Modal.init(elems, optionsModal);

  initMaterialize();

  $("[data-sortable]").each(function () {
    $(this).sortable({
      handle: ".handle",
      placeholder: "sortable-placeholder",
      update: function (e, ui) {
        Rails.ajax({
          url: $(this).data("url"),
          type: "PATCH",
          data: $(this).sortable("serialize"),
        });
      },
    });
  });

  const surveyForm = $("#survey-property-select");

  if (surveyForm.length > 0) {
    toggleLeasingAgentSelect(surveyForm);
  }
});

document.addEventListener("DOMContentLoaded", function () {
  // var completionDateInput = $("#completion-date");
  // var renovationStatusSelect = $("#renovation-status-select");
  // if (completionDateInput.length === 0) {
  //   return;
  // }
  // // Store the original options
  // var originalOptions = renovationStatusSelect.html();
  // // Update the "Complete" option on page load
  // updateCompleteOption();
  // // Listen for changes in the completion_date field
  // completionDateInput.on("change", function () {
  //   updateCompleteOption();
  // });
  // // Function to update the "Complete" option based on the completion_date value
  // function updateCompleteOption() {
  //   var completionDate = completionDateInput.val();
  //   // Re-populate the select options based on the completion_date value
  //   if (completionDate) {
  //     renovationStatusSelect.html(originalOptions);
  //   } else {
  //     renovationStatusSelect.html(originalOptions.replace(/<option value="complete">Complete<\/option>/, ""));
  //   }
  //   M.FormSelect.init(renovationStatusSelect); // Reinitialize the Materialize CSS select element
  // }
  // // Trigger the change event on completion date input on page load
  // completionDateInput.trigger("change");
});

const initMaterialize = function () {
  var elems = document.querySelectorAll("select");
  M.FormSelect.init(elems, { classes: "select" });
  var elems = document.querySelectorAll(".datepicker");
  M.Datepicker.init(elems, { format: "yyyy-mm-dd", showClearBtn: true });
  var elems = document.querySelectorAll(".tabs");
  M.Tabs.init(elems);
  var elems = document.querySelectorAll(".tooltipped");
  M.Tooltip.init(elems);
  var elems = document.querySelectorAll(".timepicker");
  var instances = M.Timepicker.init(elems, { twelveHour: true, showClearBtn: true });
  var dropdowns = document.querySelectorAll(".dropdown-trigger");
  for (var i = 0; i < dropdowns.length; i++) {
    M.Dropdown.init(dropdowns[i], { coverTrigger: false, constrainWidth: false });
  }
  var elems = document.querySelectorAll(".sidenav");
  var instances = M.Sidenav.init(elems);
  var elems = document.querySelectorAll(".materialboxed");
  var instances = M.Materialbox.init(elems);
  var elems = document.querySelectorAll(".tabs");
  var instances = M.Tabs.init(elems);
};

const setContestedModalValues = function (elem) {
  const instance = M.Modal.getInstance(elem);
  const caller = instance._openingTrigger;
  const callScoreId = caller.dataset.callScoreId;
  const surveyResultId = caller.dataset.surveyResultId;
  const locationName = caller.dataset.locationName;
  const form = document.forms["new_contested_call_score"];
  const form_callScoreId = form.elements["contested_call_score[call_score_id]"];
  const form_surveyResultId = form.elements["contested_call_score[survey_result_id]"];
  const form_locationName = form.elements["contested_call_score[location]"];
  console.log("LOCATION NAME", locationName);
  console.log("RESULT ID", surveyResultId);
  form_callScoreId.value = callScoreId;
  form_surveyResultId.value = surveyResultId;
  form_locationName.value = locationName;
};

document.addEventListener("vanilla-nested:fields-added", function (e) {
  initMaterialize();
});

window.toggleSurveyQuestions = function (e) {
  const questionId = e.attributes["data-question"].value;
  const value = e.value;
  const aggregatable_questions = ["select", "radio", "checkbox"];
  if (aggregatable_questions.includes(value)) {
    $("#question-" + questionId).removeClass("hide");
  } else {
    $("#question-" + questionId).removeClass("hide");
    $("#question-" + questionId).addClass("hide");
  }
};

window.toggleLeasingAgentSelect = function (e) {
  var propertyId = $(e).val();
  var leasingAgentSelect = document.getElementById("survey-leasing-agent-select");
  var selected = leasingAgentSelect.getAttribute("data-selected");

  if (propertyId) {
    $.ajax({
      url: "/properties/" + propertyId + "/leasing_agents",
      method: "GET",
      dataType: "json",
      error: function (xhr, status, error) {
        console.error("AJAX Error: " + status + error);
      },
      success: function (response) {
        var leasingAgents = response["leasing_agents"];
        var i,
          lObj = leasingAgentSelect.options.length - 1;
        for (i = lObj; i >= 0; i--) {
          leasingAgentSelect.remove(i);
        }

        leasingAgentSelect.append("<option>Select leasing-agent</option>");
        for (var i = 0; i < leasingAgents.length; i++) {
          var isSelected = leasingAgents[i][1] == selected ? "selected" : "";
          leasingAgentSelect.insertAdjacentHTML("beforeend", '<option value="' + leasingAgents[i][1] + '"' + isSelected + ">" + leasingAgents[i][0] + "</option>");
        }
        const controllerElement = document.querySelector('[data-controller="tracker-unknown-input"]');
        const controller = application.getControllerForElementAndIdentifier(controllerElement, "tracker-unknown-input");
        controller.toggleUnknown();
      },
    });
  }
};

$(document).on("change", "#survey-property-select", function () {
  toggleLeasingAgentSelect(this);
});
